pep-typeahead {
  white-space: normal;

  display: flex;
  position: relative;
  background-color:white;
  padding: 4px 2px 1px 2px;
  border: 1px solid #ccc;
  border-radius: 12px;
}

pep-typeahead:focus-within {
  box-shadow: 0px 0px 0px 3px rgb(155, 203, 255);
  outline: none;
}

pep-typeahead.invalid {
  box-shadow: 0px 0px 0px 3px rgb(255, 129, 129);
}

pep-typeahead .typeahead-input-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding: 6px 8px;
  border-radius: 6px;
}

pep-typeahead .input {
  flex: 1;
  outline: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  border-radius: 8px;
  min-width: 60px;

  /* Auto-sizing stuff */
  font-family: inherit;
  font-size: inherit;
  padding: 2px 24px 2px 2px;
}

pep-typeahead .typeahead-clear {
  cursor: pointer;
  height: 1rem;
  line-height: 1rem;
  margin-top: -0.7rem;
  position: absolute;
  right: 0.25rem;
  top: 50%;
  opacity: 50%;
}

pep-typeahead .typeahead-clear svg {
  width: 24px;
  height: 24px;
}

/* Multi */

/* .elm-select-multi-input-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.elm-select-multi-input-item {
  display: flex;
  border-width: 1px;
  background-color: #E3E5E8;
  border-radius: 4px;
  margin-right: 4px;
  padding: 2px 8px;
}

.elm-select-multi-input-item-text {
  text-overflow: ellipsis;
}

.elm-select-multi-input-item-remove {
  display: flex;
  align-items: center;
  justify-content: center;
}

.elm-select-multi-item-remove {
  cursor: pointer;
  padding-top: 1px;
  line-height: 0px;
} */

/* Menu */

pep-typeahead .typeahead-menu {
  z-index: 1;

  opacity: initial;
  transition: opacity ease-out 2000ms;

  min-width: 8rem;
  max-height: 15rem;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  position: absolute;
  overflow-y: scroll;
  margin-top: 40px;

  padding: 0;
  list-style-type: none;
  border-radius: 8px;
}

pep-typeahead .typeahead-menu-item {
  padding: 12px 8px;
}

pep-typeahead .typeahead-menu-item-selectable {
  cursor: pointer;
}

pep-typeahead .typeahead-menu-item-selectable:hover {
  background-color: #e0e9ff;
}

pep-typeahead.menu-fade-out .typeahead-menu {
  transition: opacity ease-out 100ms;
  opacity: 0;
}