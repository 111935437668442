pep-typeahead {
  white-space: normal;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 4px 2px 1px;
  display: flex;
  position: relative;
}

pep-typeahead:focus-within {
  outline: none;
  box-shadow: 0 0 0 3px #9bcbff;
}

pep-typeahead.invalid {
  box-shadow: 0 0 0 3px #ff8181;
}

pep-typeahead .typeahead-input-wrapper {
  background-color: #fff;
  border-radius: 6px;
  padding: 6px 8px;
  display: flex;
  position: relative;
  overflow: hidden;
}

pep-typeahead .input {
  font-size: inherit;
  min-width: 60px;
  font-family: inherit;
  font-size: inherit;
  border: none;
  border-radius: 8px;
  outline: none;
  flex: 1;
  padding: 2px 24px 2px 2px;
}

pep-typeahead .typeahead-clear {
  cursor: pointer;
  height: 1rem;
  opacity: .5;
  margin-top: -.7rem;
  line-height: 1rem;
  position: absolute;
  top: 50%;
  right: .25rem;
}

pep-typeahead .typeahead-clear svg {
  width: 24px;
  height: 24px;
}

pep-typeahead .typeahead-menu {
  z-index: 1;
  opacity: initial;
  min-width: 8rem;
  max-height: 15rem;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-top: 40px;
  padding: 0;
  list-style-type: none;
  transition: opacity 2s ease-out;
  position: absolute;
  overflow-y: scroll;
}

pep-typeahead .typeahead-menu-item {
  padding: 12px 8px;
}

pep-typeahead .typeahead-menu-item-selectable {
  cursor: pointer;
}

pep-typeahead .typeahead-menu-item-selectable:hover {
  background-color: #e0e9ff;
}

pep-typeahead.menu-fade-out .typeahead-menu {
  opacity: 0;
  transition: opacity .1s ease-out;
}

/*# sourceMappingURL=index.72a15a5a.css.map */
